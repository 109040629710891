export enum CookieNames {
  Locale = "Locale",
  DeviceToken = "device-token",
  SecurityToken = "security-token"
};

export enum LocalStorageNames {
  Locale = "Locale",
  DeviceToken = "DeviceToken",
  SecurityToken = "SecurityToken",
  TenantInfo = "TenantInfo",
  UserInfo = "UserInfo",
  HideNonLeaveMenus = "HideNonLeaveMenus",
  LeaveAndAbsenceFilterData = "leaveAndAbsenceFilterData",
  FoodMenuTabData = "foodMenuTabData",
  WorkPlanning = "workPlanningLocalStorageData",
  EnableGateArrivalsMenu = "EnableGateArrivalsMenu",
  FuelBookingListFilterModel = "pipeBookingFilterData",
  EnableWorkOverViewMenu = "EnableWorkOverViewMenu",
  EnableWorkReport ="EnableWorkReport"
};
