import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';

import { ErrorDialogComponent } from '../core/shared/error-dialog/error-dialog.component'

import { LoggerService } from '../core/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private zone: NgZone,
    private loggerService: LoggerService,
    public dialog: MatDialog
  ) {}

  handleError(error: HttpErrorResponse|Error|any) {

    this.zone.run(() => {
      var errorMessage = ``;

      if (error instanceof HttpErrorResponse) {
        errorMessage += JSON.stringify(error, null, 2);
      }
      // stack is a non-standard property so we check if it is present
      else if (error.stack) {
        errorMessage += `${error.message}\n${error.stack}`;
      }
      else {
        errorMessage += `${error.message}`;
      }

      this.loggerService.error(errorMessage);

      if (!!this.dialog.openDialogs && this.dialog.openDialogs.length > 0)
        return;

      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: errorMessage,
          type: error.name
        },
        maxHeight: "100%",
        width: "540px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });
    });
  }
}
