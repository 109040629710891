import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable()
export class SpinnerService {
  private showSpinner = new Subject();
  private delayedShow: boolean = false;

  getData() {
      return this.showSpinner;
  }

  show(delay: number = 0)
  {
    if (delay > 0) {
      this.delayedShow = true;
      setTimeout(() => {
        if (this.delayedShow) {
          this.delayedShow = false;
          this.showSpinner.next(true);
          return;
        }

        this.delayedShow = false;
      }, delay);
    }
    else {
      this.showSpinner.next(true);
    }
  }

  hide() {
    this.delayedShow = false;

    this.showSpinner.next(false);
  }
}
