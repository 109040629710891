import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap, distinctUntilChanged } from 'rxjs/operators';

import { ConfigurationService } from './configuration.service';
import { TextModel } from '../model/text.model';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  private _bsTexts = new BehaviorSubject<TextModel[]>([]);

  readonly texts = this._bsTexts.asObservable().pipe(distinctUntilChanged());

  textsValue: TextModel[] = [];

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService
  ) {
  }

  public fetchTexts(): Observable<TextModel[]> {

    var textsUrl = this.configurationService.getApiUrl('Text');
    var apiHeaders = this.configurationService.getApiHeaders();

    return this.http.get<TextModel[]>(textsUrl, { headers: apiHeaders })
      .pipe(
        tap(data => {
          this.textsValue = data;
          this._bsTexts.next(data);
        }),
        catchError(this.handleError)
      );
  }

  public fetchTextByKeys(key: string, langKey: string): Observable<TextModel> {

    var textsUrl = this.configurationService.getApiUrl('Text/ByKeys');

    textsUrl += "/" + key + "/" + langKey;

    var apiHeaders = this.configurationService.getApiHeaders();

    return this.http.get<TextModel>(textsUrl, { headers: apiHeaders })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    // In a real world app, we might send the error to remote logging infrastructure
    // and reformat for user consumption
    console.error(error); // log to console instead
    return throwError(error);
  }
}
