import { Injectable } from '@angular/core';

import { LocalStorageService } from 'ngx-localstorage';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '../../../services/authentication.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { HomeService } from '../../../services/home.service';

import { ConfigurationModel } from '../../../model/configuration.model';
import { TenantModel } from '../../../model/tenant.model';
import { UserInformationModel } from '../../../model/user.model';
import { NewsItemModel } from '../../../model/newsitem.model';

import {
  SenchaSettingModel, SenchaUserInformationModel, SenchaEmployeeModel, SenchaHeroCarouselItemModel
} from '../model/sencha.model';
import { LocalStorageNames } from '../sencha.statics';

@Injectable({
  providedIn: 'root',
})
export class SenchaLocalStorageService {
  private currentUser: UserInformationModel;
  private configurationModel: ConfigurationModel;
  private tenantModel: TenantModel;
  private homeItems = [];
  private currentLang: string = "en";

  constructor(
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private homeService: HomeService,
  ) {
    this.authenticationService.currentUser
      .subscribe(x => {
        this.currentUser = x;

        this.writeSettingsModel();
      });

    this.translate.onLangChange
      .subscribe(lang => {
        this.currentLang = lang.lang;

        this.writeSettingsModel();
      });

    this.configurationService.allConfigsLoaded
      .subscribe(x => {

        if (x === true) {
          this.configurationModel = this.configurationService.getCurrentConfiguration();
          this.tenantModel = this.configurationService.getCurrentTenant();

          this.writeSettingsModel();
        }
      });

    this.homeService.newsItems
      .subscribe(x => {
        this.homeItems = x;

        this.writeHeroCarouselItems();
      });
  }

  private writeSettingsModel(): void
  {
    if (!this.configurationModel) {
      return;
    }

    if (!this.tenantModel) {
      return;
    }

    var deviceToken   = this.configurationService.getCurrentDeviceToken();
    var securityToken = this.configurationService.getCurrentSecurityToken();

    var model = new SenchaSettingModel;
    model.DeviceToken           = deviceToken;
    model.Id                    = 0;
    model.InAppLaunched         = false;
    model.Key                   = this.configurationModel.ConfigurationKey;
    model.Language              = this.currentLang;
    model.Version = this.tenantModel.Version;

    if (securityToken) {
      model.SecurityToken = securityToken.SecurityToken;
      model.SecurityTokenExpires = (new Date(securityToken.ValidUntil)).getTime();
    }

    if (this.currentUser)
    {
      model.DefaultSecuredArea  = this.currentUser.DefaultSecuredArea;
      model.UserType            = this.currentUser.UserType;
      model.UserRoles           = this.currentUser.UserRoles;

      model.UserInformation = new SenchaUserInformationModel;
      model.UserInformation.Email               = this.currentUser.Email;
      model.UserInformation.FirstName           = this.currentUser.FirstName;
      model.UserInformation.GdprConfirmRequired = this.currentUser.GdprConfirmRequired;
      model.UserInformation.LastName            = this.currentUser.LastName;
      model.UserInformation.MobilePhone         = this.currentUser.MobilePhone;
      model.UserInformation.Remark              = this.currentUser.Remark;
      model.UserInformation.UserId              = this.currentUser.UserId;
      model.UserInformation.UserName            = this.currentUser.UserName;

      model.UserInformation.Employee = new SenchaEmployeeModel;
      if (this.currentUser.Employee)
      {
        model.UserInformation.Employee.Active = this.currentUser.Employee.Active;
        model.UserInformation.Employee.Code = this.currentUser.Employee.Code;
        model.UserInformation.Employee.Description = this.currentUser.Employee.Description;
        model.UserInformation.Employee.EmployeeGroups = this.currentUser.Employee.EmployeeGroups.splice(0);
        model.UserInformation.Employee.EmployeeTrades = this.currentUser.Employee.EmployeeTrades.splice(0);
        model.UserInformation.Employee.Id = this.currentUser.Employee.Id;
        model.UserInformation.Employee.Name = this.currentUser.Employee.Name;
      }
    }

    this.localStorageService.set(LocalStorageNames.SettingsModel, model.Id);
    this.localStorageService.set(LocalStorageNames.SettingsModel + "-" + model.Id, model);
  }

  private writeHeroCarouselItems(): void {

    var carouselItems = new Array<SenchaHeroCarouselItemModel>();

    this.homeItems.forEach((item: NewsItemModel) => {
      var model = new SenchaHeroCarouselItemModel;
      model.Category        = item.Category;
      model.Changed         = item.Changed;
      model.FileId          = item.FileId;
      model.Heading         = item.Heading;
      model.Id              = ("_" + item.Id);
      model.Image           = item.Image;
      model.Information     = item.Information;
      model.LinkDestination = item.LinkDestination;
      model.LinkType        = item.LinkType;
      model.SortOrder       = item.SortOrder;

      carouselItems.push(model);
    });


    var ids: any = carouselItems.map(x => x.Id).join(",");
    this.localStorageService.set(LocalStorageNames.HeroCarouselItem, ids);

    carouselItems.forEach((item: SenchaHeroCarouselItemModel) => {
      this.localStorageService.set(LocalStorageNames.HeroCarouselItem + "-" + item.Id, item);
    });
  }
}
