<h1 mat-dialog-title>
    <ng-template [ngIf]="data.title" [ngIfElse]="defaultTitle">
        {{data.title}}
    </ng-template>

    <ng-template #defaultTitle>
        {{ 'errordialog.dialogTitle' | translate }}
    </ng-template>
</h1>
<div mat-dialog-content>
    <p *ngIf="data.status"><b>{{ 'errordialog.statusCodeLabel' | translate }}: {{data.status}}</b></p>
    <p *ngIf="data.type"><b>{{ 'errordialog.typeLabel' | translate }}: {{data.type}}</b></p>
    <pre *ngIf="data.message" class="error-message">{{data.message}}</pre>
    <p *ngIf="data.content"> {{data.content}} </p>
</div>
<div mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close color="primary">{{ 'common.close' | translate }}</button>
</div>