import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { timeout, catchError, distinctUntilChanged } from 'rxjs/operators';

import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeSkinService {

  private renderer: Renderer2;

  styleTag: string = "";

  private _bsDarkMode = new BehaviorSubject<boolean>(false);
  private _bsAltSkin = new BehaviorSubject<boolean>(false);

  readonly darkMode = this._bsDarkMode.asObservable().pipe(distinctUntilChanged());
  readonly altSkin = this._bsAltSkin.asObservable().pipe(distinctUntilChanged());

  constructor(
    @Inject(DOCUMENT) private document: Document,    
    rendererFactory: RendererFactory2,
    private configurationService: ConfigurationService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.configurationService.tenant.subscribe(x => {

      if (x && x.StyleKey) {
        this.styleTag = x.StyleKey;
      }
    });
  }

  public toggleDarkMode(): boolean {
    var newValue = !this._bsDarkMode.value;

    if (newValue) {
      this.renderer.addClass(document.body, 'dark');
    }
    else {
      this.renderer.removeClass(document.body, 'dark');
    }

    this._bsDarkMode.next(newValue);

    return newValue;
  }

  public toggleAltSkin(): boolean {
    var newValue = !this._bsAltSkin.value;

    if (newValue) {
      this.renderer.addClass(document.body, 'STAVANGER');
      this.renderer.removeClass(document.body, this.styleTag);
    }
    else {
      this.renderer.addClass(document.body, this.styleTag);
      this.renderer.removeClass(document.body, 'STAVANGER');
    }

    this._bsAltSkin.next(newValue);

    return newValue;
  }
}
