import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfigurationService } from '../services/configuration.service';
import { TenantModel } from '../model/tenant.model';
import { TextModel } from '../model/text.model';
import { UserModel, UserInformationModel } from '../model/user.model';

import { UserTypeEnum } from '../../_enums/usertype.enum';
import { UserRoleEnum } from '../../_enums/userrole.enum';

@Injectable({
  providedIn: 'root'
})
export class TextHelper
{
  private tenantModel: TenantModel;

  constructor(
    private configurationService: ConfigurationService,
    private translate: TranslateService
  ) {
    this.configurationService.tenant
      .subscribe(x => {
        this.tenantModel = x;
      });
  }

  public toTitleCase(theString: string, seperator: string = ' '): string {
    var s = theString.toLowerCase();
    var parts = s.split(seperator);
    var transforms: string[] = [];
    for (var i = 0; i < parts.length; i++) {
      var p = parts[i];
      if (p.length > 1) {
        p = p[0].toUpperCase() + p.substr(1);
        transforms.push(p);
      }
      else if (p.length == 1) {
        p = p[0].toUpperCase();
        transforms.push(p);
      }
    }
    return transforms.join(seperator);
  }

  public resolveTranslation(key: string): string {
    if (key && key.substr(0, 1) == '_') {
      var k = key.substr(1);
      var p = {};

      switch (k) {
        case "about.title": {
          p["harbour"] = '' + (this.tenantModel ? this.tenantModel.HarbourName : '');
        } break;
      }

      return this.translate.instant(k, p);
    }
    else {
      return key;
    }
  }

  public getTenantText(key: string): string {
    if (this.tenantModel.Texts.length <= 0)
      return null;

    var tmp = this.tenantModel.Texts.find(t => t.Name == key);
    if (tmp !== undefined) {
      return tmp.Text;
    }

    return null;
  }

  public getTenantTextModel(key: string): TextModel {
    if (this.tenantModel.Texts.length <= 0)
      return null;

    var tmp = this.tenantModel.Texts.find(t => t.Name == key);
    if (tmp !== undefined) {
      return tmp;
    }

    return null;
  }

  public generateUserTypeSummery(user: UserInformationModel): string {
    var out: string = "";

    switch (user.UserType) {
      case UserTypeEnum.Internal: { out += this.translate.instant("usertype.internal"); } break;
      case UserTypeEnum.External: { out += this.translate.instant("usertype.external"); } break;
      case UserTypeEnum.None: { out += this.translate.instant("usertype.none"); } break;
    }

    return out;
  }

  public generateUserRolesSummery(user: UserInformationModel, separator?: string): string {
    var sep: string = (separator ? separator : ",");
    var out: string = "";
    var userRoles: number[] = [];
    var checkForArray: Array<number> = user.UserRoles;
    if (!(checkForArray instanceof Array)) {
      userRoles.push(checkForArray as number)
    }
    else {
      userRoles = user.UserRoles;
    }
    userRoles.forEach((r) => {
      if (out.length > 0)
        out += sep;

      switch (r) {
        case UserRoleEnum.Disabled: { out += this.translate.instant("userrole.disabled"); } break;
        case UserRoleEnum.User: { out += this.translate.instant("userrole.user"); } break;
        case UserRoleEnum.SuperUser: { out += this.translate.instant("userrole.superuser"); } break;

        case UserRoleEnum.CustomerAdministrator: { out += this.translate.instant("userrole.customerAdmin"); } break;
        case UserRoleEnum.ContentAdministrator: { out += this.translate.instant("userrole.contentAdmin"); } break;
        case UserRoleEnum.InPortExternalApps: { out += this.translate.instant("userrole.inportExternalApps"); } break;

        case UserRoleEnum.GateCustomerReceiver: { out += this.translate.instant("userrole.gateCustomerReceiver"); } break;
        case UserRoleEnum.GateCustomerReceiverAdmin: { out += this.translate.instant("userrole.gateCustomerReceiverAdmin"); } break;
        case UserRoleEnum.GateHarbourSecurityOfficer: { out += this.translate.instant("userrole.gateHarbourSecurityOfficer"); } break;
        case UserRoleEnum.GateGuard: { out += this.translate.instant("userrole.gateGuard"); } break;
        case UserRoleEnum.GateUserAccessCardHolder: { out += this.translate.instant("userrole.gateUserAccessCardHolder"); } break;

        case UserRoleEnum.EamWorkOrder: { out += this.translate.instant("userrole.eamWorkOrder"); } break;
        case UserRoleEnum.MaintenanceRequestor: { out += this.translate.instant("userrole.maintenanceRequestor"); } break;
        case UserRoleEnum.MaintenanceExternalTechnician: { out += this.translate.instant("userrole.maintenanceExternalTechnician"); } break;
        case UserRoleEnum.MaintenanceTechnician: { out += this.translate.instant("userrole.maintenanceTechnician"); } break;
        case UserRoleEnum.MaintenanceManager: { out += this.translate.instant("userrole.maintenanceManager"); } break;

        case UserRoleEnum.AgentPortalShipAgent: { out += this.translate.instant("userrole.agentPortalShipAgent"); } break;
        case UserRoleEnum.AgentPortalShipTerminalOperator: { out += this.translate.instant("userrole.agentPortalShipTerminalOperator"); } break;
        case UserRoleEnum.AgentPortalGoodsOwner: { out += this.translate.instant("userrole.agentPortalGoodsOwner"); } break;
        case UserRoleEnum.AgentPortalPortControl: { out += this.translate.instant("userrole.agentPortalPortControl"); } break;
        case UserRoleEnum.AgentPortalSteveDoringCo: { out += this.translate.instant("userrole.agentPortalSteveDoringCo"); } break;

        case UserRoleEnum.HarbourVgmAdministrator: { out += this.translate.instant("userrole.harbourVgmAdministrator"); } break;
        case UserRoleEnum.VgmCustomer: { out += this.translate.instant("userrole.vgmCustomer"); } break;
        case UserRoleEnum.VgmCustomerAdministrator: { out += this.translate.instant("userrole.vgmCustomerAdministrator"); } break;

        case UserRoleEnum.WorkOrderDocker: { out += this.translate.instant("userrole.workOrderDocker"); } break;
        case UserRoleEnum.WorkOrderExternal: { out += this.translate.instant("userrole.workOrderExternal"); } break;
        case UserRoleEnum.WorkOrderForeman: { out += this.translate.instant("userrole.workOrderForeman"); } break;
        case UserRoleEnum.WorkOrderHarbourAdministrator: { out += this.translate.instant("userrole.workOrderHarbourAdministrator"); } break;

        case UserRoleEnum.BunkerPortControl: { out += this.translate.instant("userrole.bunkerPortControl"); } break;
        case UserRoleEnum.BunkerOperatorAdmin: { out += this.translate.instant("userrole.bunkerOperatorAdmin"); } break;
        case UserRoleEnum.BunkerOperator: { out += this.translate.instant("userrole.bunkerOperator"); } break;
        case UserRoleEnum.BunkerMobileUser: { out += this.translate.instant("userrole.bunkerMobileUser"); } break;

        case UserRoleEnum.DockerDocker: { out += this.translate.instant("userrole.dockerDocker"); } break;
        case UserRoleEnum.DockerExtra: { out += this.translate.instant("userrole.dockerExtra"); } break;
        case UserRoleEnum.DockerCatering: { out += this.translate.instant("userrole.dockerCatering"); } break;
        case UserRoleEnum.DockerPlanner: { out += this.translate.instant("userrole.dockerPlanner"); } break;
        case UserRoleEnum.DockerManager: { out += this.translate.instant("userrole.dockerManager"); } break;
      }
    });
    return out;
  }
}
