import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguageDbService {
  constructor(private http: HttpClient, private configurationService: ConfigurationService) {

  }
  public updateLanguage(langKey: string) {
    var langChangeUrl = this.configurationService.getApiUrl('RegisterApplication');
    var apiHeaders = this.configurationService.getApiHeaders();

    this.http.get<void>(langChangeUrl, { headers: apiHeaders, params: { languageKey: langKey } }).subscribe();
  }
  private handleError(error: any) {
    // In a real world app, we might send the error to remote logging infrastructure
    // and reformat for user consumption
    console.error(error); // log to console instead
    return throwError(error);
  }

}
