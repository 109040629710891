export class ConfigurationModel {
  ApplicationMode: string = "";
  LoggerMode: string = "R"; // (C)onsole,(R)emote,(B)oth
  UseBinaryPlaceholder: string = "";
  ShowDevFooter: string = "";
  ConfigurationKey: string;
  ApiBaseUrl: string;
  ApiClientName: string;
  Locale: string;

  isDevMode(): boolean { return this.ApplicationMode == "DEV"; }
}
