import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { timeout, tap, catchError, distinctUntilChanged } from 'rxjs/operators';

import { LocalStorageService } from 'ngx-localstorage';

import {
  AuthenticationBasicResponseModel, ChangePasswordModel,
  ChangePasswordResponseModel, RecoverPasswordResponseModel,
  GdprConfirmResponseModel, AuthenticationPrepareResponseModel, SecurityTokenModel
} from '../model/authentication.model';

import { UserInformationModel } from '../model/user.model';
import { UserInfoModel } from '../model/userinfo.model';
import { TokenModel } from '../model/token.model';
import { ConfigurationService } from './configuration.service';
import { LocalStorageNames } from '../core.statics';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _bsCurrentUser: BehaviorSubject<UserInformationModel>;

  readonly currentUser: Observable<UserInformationModel>;

  private _bsSecurityToken: BehaviorSubject<SecurityTokenModel>;

  readonly securityToken: Observable<SecurityTokenModel>;

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private localStorageService: LocalStorageService
  ) {
    var userInfo = this.localStorageService.get(LocalStorageNames.UserInfo);
    var securityInfo = this.localStorageService.get(LocalStorageNames.SecurityToken);

    this._bsCurrentUser = new BehaviorSubject<UserInformationModel>(userInfo);
    this._bsSecurityToken = new BehaviorSubject<SecurityTokenModel>(securityInfo);

    this.currentUser = this._bsCurrentUser.asObservable().pipe(distinctUntilChanged());
    this.securityToken = this._bsSecurityToken.asObservable().pipe(distinctUntilChanged());
  }

  public get currentUserValue(): UserInformationModel {
    return this._bsCurrentUser.value;
  }

  public get securityTokenValue(): SecurityTokenModel {
    return this._bsSecurityToken.value;
  }

  public get securityTokenValueFromLocalStorage(): SecurityTokenModel {
    var ss = this.localStorageService.get(LocalStorageNames.SecurityToken);
    if (ss != null)
      this._bsSecurityToken.next(ss);
    else
      this._bsSecurityToken.next(undefined);
    return this._bsSecurityToken.value;
  }

  public setCurrentUserValue(userInfo: UserInformationModel, userType?: number, userRoles?: Array<number>, hideNonLeaveMenus?: boolean, securityToken?: TokenModel, validUntil?: Date, enableGateArrivalsMenu?: boolean, enableWorkOverViewMenu?: boolean, enableWorkReport?: boolean): void {

    if (!!userType)
      userInfo.UserType = userType;

    if (!!userRoles)
      userInfo.UserRoles = userRoles;

    userInfo.Initials = this.generateInitials(userInfo.FirstName, userInfo.LastName);
    userInfo.CustomerId = userInfo.CustomerId;

    this.localStorageService.set(LocalStorageNames.UserInfo, userInfo);
    this.localStorageService.set(LocalStorageNames.HideNonLeaveMenus, hideNonLeaveMenus);
    this.localStorageService.set(LocalStorageNames.EnableGateArrivalsMenu, enableGateArrivalsMenu);
    this.localStorageService.set(LocalStorageNames.EnableWorkOverViewMenu, enableWorkOverViewMenu);
    this.localStorageService.set(LocalStorageNames.EnableWorkReport, enableWorkReport);
    var s: SecurityTokenModel = new SecurityTokenModel
    s.SecurityToken = securityToken.Value;
    s.ValidUntil = new Date(validUntil);

    this.localStorageService.set(LocalStorageNames.SecurityToken, s);

    this._bsCurrentUser.next(userInfo);
    this._bsSecurityToken.next(s);
  }

  private generateInitials(fn?: string, ln?: string): string {
    var o: string = '' + (fn && fn.length > 0 ? fn.substr(0, 1) : '');
    return o + (ln && ln.length > 0 ? ln.substr(0, 1) : '');
  }

  public updateUserValues(model: UserInfoModel): void {
    var userInfo: UserInformationModel = JSON.parse(JSON.stringify(this._bsCurrentUser.value));

    userInfo.FirstName = model.firstname;
    userInfo.LastName = model.lastname;
    userInfo.MobilePhone = model.phonenumber;
    userInfo.Email = model.email;

    userInfo.Initials = this.generateInitials(userInfo.FirstName, userInfo.LastName);

    this.localStorageService.set(LocalStorageNames.UserInfo, userInfo);

    this._bsCurrentUser.next(userInfo);
  }

  public basicLogin(username: string, password: string): Observable<AuthenticationBasicResponseModel> {
    var loginUrl = this.configurationService.getApiUrl("Authentication/Basic");
    var apiHeaders = this.configurationService.getApiHeaders();
    var body = JSON.stringify({
      "Username": username,
      "Password": password
    });

    return this.http.post<AuthenticationBasicResponseModel>(loginUrl, body, { headers: apiHeaders })
      .pipe(
        tap(
          data => {
            if (data.Success && data.UserInformation) {
              this.setCurrentUserValue(data.UserInformation, data.UserType, data.UserRoles, data.HideNonLeaveMenus, data.SecurityToken, data.UtcValidUntil, data.EnableGateArrivalsMenu, data.EnableWorkOverViewMenu, data.EnableWorkReport);
            }
          }),
        catchError(this.handleError)
      );
  }

  public preparePinCodeLogin(username: string, password: string): Observable<AuthenticationPrepareResponseModel> {
    var loginUrl = this.configurationService.getApiUrl("Authentication/SMS");
    var apiHeaders = this.configurationService.getApiHeaders();

    var body = JSON.stringify({
      "Username": username,
      "Password": password
    });

    return this.http.post<AuthenticationPrepareResponseModel>(loginUrl, body, { headers: apiHeaders })
      .pipe(
        catchError(this.handleError)
      );
  }

  public verifyPinCodeLogin(username: string, pincode: string): Observable<AuthenticationBasicResponseModel> {
    var loginUrl = this.configurationService.getApiUrl("Authentication/SMS");
    var apiHeaders = this.configurationService.getApiHeaders();

    var body = JSON.stringify({
      "Username": username,
      "Password": pincode
    });

    return this.http.put<AuthenticationBasicResponseModel>(loginUrl, body, { headers: apiHeaders })
      .pipe(
        tap(
          data => {
            if (data.Success && data.UserInformation) {
              this.setCurrentUserValue(data.UserInformation, data.UserType, data.UserRoles, data.HideNonLeaveMenus, data.SecurityToken, data.UtcValidUntil, data.EnableGateArrivalsMenu, data.EnableWorkOverViewMenu, data.EnableWorkReport);
            }
          }),
        catchError(this.handleError)
      );
  }

  public logout(): Observable<void> {
    var logoutUrl = this.configurationService.getApiUrl("Authentication/Basic");
    var apiHeaders = this.configurationService.getApiHeaders();
    return this.http.delete<void>(logoutUrl, { headers: apiHeaders })
      .pipe(
        tap(
          data => {
            this.logoutSoft();
          }),
        catchError(this.handleError)
      );
  }

  public logoutSoft(): void {
    this.configurationService.clearAuthenticationInfo();

    // remove user from local storage to log user out
    this.localStorageService.remove(LocalStorageNames.UserInfo);
    this.localStorageService.remove(LocalStorageNames.LeaveAndAbsenceFilterData);
    this.localStorageService.remove(LocalStorageNames.FuelBookingListFilterModel);
    this.localStorageService.remove(LocalStorageNames.HideNonLeaveMenus);
    this.localStorageService.remove(LocalStorageNames.EnableGateArrivalsMenu);
    this.localStorageService.remove(LocalStorageNames.FoodMenuTabData);
    this.localStorageService.remove(LocalStorageNames.WorkPlanning);
    this.localStorageService.remove(LocalStorageNames.SecurityToken);
    this.localStorageService.remove(LocalStorageNames.EnableWorkOverViewMenu);
    this.localStorageService.remove(LocalStorageNames.EnableWorkReport);

    this._bsCurrentUser.next(undefined);
    this._bsSecurityToken.next(undefined);
  }

  public getSingleSignOnToken(paramUrl: string): Observable<TokenModel> {
    var loginUrl = this.configurationService.getApiUrl("Authentication/SingleSignOn/RequestToken");
    var apiHeaders = this.configurationService.getApiHeaders();
    var tenantModel = this.configurationService.getCurrentTenant();
    var body = JSON.stringify({
      "Url": paramUrl
    });

    return this.http.post<TokenModel>(loginUrl, body, { headers: apiHeaders })
      .pipe(
        tap(
          data => {
            /*if (data && data.Value != null) {
              console.info("Got token " + data.Value);
            }*/
          }          
        ),
        catchError((error: HttpErrorResponse): Observable<any> => {
          if (error.status === 401) {
            alert("Session expired!");
            let logoutUrl = tenantModel.AdminUrl + '/Logout';
            window.location.href = logoutUrl;
          }
          
          return throwError(() => error);
        },)
      );
  }

  public changePassword(model: ChangePasswordModel): Observable<ChangePasswordResponseModel> {
    var changePasswordUrl = this.configurationService.getApiUrl("Authentication/Change/Password");
    var apiHeaders = this.configurationService.getApiHeaders();

    var body = JSON.stringify({
      "CurrentPassword": model.oldPassword,
      "NewPassword": model.newPassword,
      "RepeatNewPassword": model.newPassword2
    });

    return this.http.post<ChangePasswordResponseModel>(changePasswordUrl, body, { headers: apiHeaders })
      .pipe(
        catchError(this.handleError)
      );
  }

  public recoverPassword(username: string): Observable<RecoverPasswordResponseModel> {
    var changePasswordUrl = this.configurationService.getApiUrl("Authentication/Recover/Password");
    var apiHeaders = this.configurationService.getApiHeaders();

    var body = JSON.stringify({
      "Username": username
    });

    return this.http.post<RecoverPasswordResponseModel>(changePasswordUrl, body, { headers: apiHeaders })
      .pipe(
        catchError(this.handleError)
      );
  }

  public acceptGdprTerms(): Observable<GdprConfirmResponseModel> {
    var gdprConfirmUrl = this.configurationService.getApiUrl("GdprConfrm");
    var apiHeaders = this.configurationService.getApiHeaders();

    return this.http.post<GdprConfirmResponseModel>(gdprConfirmUrl, "", { headers: apiHeaders })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    // In a real world app, we might send the error to remote logging infrastructure
    // and reformat for user consumption
    console.error(error); // log to console instead
    //  this.logoutSoft();
    return throwError(error);
  }
}
