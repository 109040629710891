import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

import {
  OvertimeMealLabelEnum, OvertimeMealStaticCodesEnum
} from '../docker/_enums/docker.enum';

import { DockerUserModel } from '../docker/model/docker-user.model';
/*import { DockerLeaveModel } from '../docker/model/docker-leave.model';
import { DockerAbsenceModel } from '../docker/model/docker-absence.model';*/
import { DockerLeaveReportModel } from '../docker/model/docker-leave-report.model';
import { DockerOvertimeMealOptionModel } from '../docker/model/docker-work-interest.model';

@Injectable()
export class InMemProgDataService implements InMemoryDbService {

  constructor() { }

  // createDb(reqInfo?: RequestInfo) {
  createDb() {

    const requestLeave: DockerLeaveReportModel[] = [];

    const reportAbsence: DockerLeaveReportModel[] = [];

    const dockerUserInformation: DockerUserModel[] = [
      // {
      //   id: 2166,
      //   userFullname: "Niclas Hällgren",
      //   userId: 2166,
      //   employeeId: 53,
      //   employeeCode: "1-80",
      //   dailyActivityItems: [
      //     {
      //       todayOffset: -1,
      //       dateString: "2021-03-25",
      //       activities: [
      //         {
      //           displayStartEndSpan: "07:00-15:45",
      //           displayText: "Vård av barn",
      //           displayLocationQuay: ""

      //         }
      //       ]
      //     },
      //     {
      //       todayOffset: 0,
      //       dateString: "2021-03-26",
      //       activities: [
      //         {
      //           displayStartEndSpan: "07:00-15:45",
      //           displayText: "Containerterminalen",
      //           displayLocationQuay: "",
      //         },
      //         {
      //           displayStartEndSpan: "15:45-19:00",
      //           displayText: "Färdigkörning fartyg",
      //           displayLocationQuay: ""
      //         }
      //       ]
      //     },
      //     {
      //       todayOffset: 1,
      //       dateString: "2021-03-27",
      //       activities: []
      //     }
      //   ],
      //   vacationDaysLeft: 25,
      //   compensationBalance: 12,
      //   messageItems: [
      //     // {
      //     //   titleString: "Behov av övertid",
      //     //   dateString: "2021-03-25",
      //     //   bodyString: "Behov av övertid för containerterminalen lördag och söndag."
      //     // },
      //     // {
      //     //   titleString: "Ledighet beviljad",
      //     //   dateString: "2021-03-24",
      //     //   bodyString: "Du har fått följande ledighetsansökan beviljad: Semester 2021-06-02 - 2021-06-08."
      //     // }
      //   ],
      //   leaveItems: [
      //     requestLeave[3],
      //     requestLeave[2],
      //     requestLeave[1],
      //     requestLeave[0]
      //   ],
      //   absenceItems: [
      //     reportAbsence[2],
      //     reportAbsence[1],
      //     reportAbsence[0]
      //   ]
      // },
      // ,
      // {
      //   id: 3388,
      //   userFullname: "Niclas Hällgren",
      //   userId: 3388,
      //   employeeId: 60,
      //   employeeCode: "1-90",
      //   dailyActivityItems: [
      //     {
      //       todayOffset: -1,
      //       dateString: "2021-03-25",
      //       activities: [
      //         {
      //           displayStartEndSpan: "07:00-15:45",
      //           displayText: "Vård av barn",
      //           displayLocationQuay: ""
      //         }
      //       ]
      //     },
      //     {
      //       todayOffset: 0,
      //       dateString: "2021-03-26",
      //       activities: [
      //         {
      //           displayStartEndSpan: "07:00-15:45",
      //           displayText: "Containerterminalen",
      //           displayLocationQuay: ""
      //         },
      //         {
      //           displayStartEndSpan: "15:45-19:00",
      //           displayText: "Färdigkörning fartyg",
      //           displayLocationQuay: ""
      //         }
      //       ]
      //     },
      //     {
      //       todayOffset: 1,
      //       dateString: "2021-03-27",
      //       activities: []
      //     }
      //   ],
      //   vacationDaysLeft: 25,
      //   compensationBalance: 12,
      //   messageItems: [
      //     // {
      //     //   titleString: "Behov av övertid",
      //     //   dateString: "2021-03-25",
      //     //   bodyString: "Behov av övertid för containerterminalen lördag och söndag."
      //     // },
      //     // {
      //     //   titleString: "Ledighet beviljad",
      //     //   dateString: "2021-03-24",
      //     //   bodyString: "Du har fått följande ledighetsansökan beviljad: Semester 2021-06-02 - 2021-06-08."
      //     // }
      //   ],
      //   leaveItems: [
      //     requestLeave[3],
      //     requestLeave[2],
      //     requestLeave[1],
      //     requestLeave[0]
      //   ],
      //   absenceItems: [
      //     reportAbsence[2],
      //     reportAbsence[1],
      //     reportAbsence[0]
      //   ]
      // }
    ];

    const overtimeMealOptions: DockerOvertimeMealOptionModel[] = [
    //  {
    //    Id: 2,
    //    code: OvertimeMealStaticCodesEnum.NOMEAL,
    //    title: "docker.mealOptions.noMeal.title",
    //    description: "docker.mealOptions.noMeal.description",
    //    food : null
    //  },
    //  {
    //    Id: 3,
    //    code: "M1",
    //    title: "Köttbullar och mos",
    //    description: "Köttbullar, mos, lingonsylt",
    //    food
    //    //labels: [OvertimeMealLabelEnum.LactosFree]
    //  },
    //  {
    //    Id: 4,
    //    code: "M2",
    //    title: "Veckans Vegetariska",
    //    description: "Chorizo, bulgur med rostade nudlar samt tzatziki",
    //    //labels: [OvertimeMealLabelEnum.Vegetarian, OvertimeMealLabelEnum.GlutenFree]
    //  },
    //  {
    //    Id: 5,
    //    code: "M3",
    //    title: "Veckans sallad",
    //    description: "BBQ Kyckling, grönsakscouscous, tomat, gurka, ananas, majs toppad med ruccola",
    //    //labels: [OvertimeMealLabelEnum.GlutenFree]
    //  },
    //  {
    //    Id: 6,
    //    code: "M4",
    //    title: "Veckans extra",
    //    description: "Kebab med pommes och mild sås"
    //  },
    //  {
    //    Id: 7,
    //    code: "M5",
    //    title: "Kåldolmar med potatis",
    //    description: "Serveras med gräddsås och lingonsylt",
    //    //labels: [OvertimeMealLabelEnum.GlutenFree]
    //  },
    //  {
    //    Id: 8,
    //    code: "M6",
    //    title: "Sprödbakad flundrafilé",
    //    description: "Serveras med kall dillsås och kokt potatis",
    //    //labels: [OvertimeMealLabelEnum.LactosFree]
    //  }
    //];

    //const overtimeSnackOptions: DockerOvertimeMealOptionModel[] = [
    //  {
    //    Id: 9,
    //    code: OvertimeMealStaticCodesEnum.NOSNACK,
    //    title: "docker.mealOptions.noSnack.title",
    //    description: "docker.mealOptions.noSnack.description"
    //  },
    //  {
    //    Id: 10,
    //    code: "S1",
    //    title: "Foccaccia kyckling curry",
    //    description: "Foccaccia på surdegsbröd med kyckling i curryröra och soltorkade tomater"
    //  },
    //  {
    //    Id: 11,
    //    code: "S2",
    //    title: "Foccaccia kyckling feta",
    //    description: "Foccaccia på surdegsbröd med kyckling i fetaostkräm"
    //  },
    //  {
    //    Id: 12,
    //    code: "S3",
    //    title: "Vegetarisk",
    //    description: "Hållkaka med ost, tomat, gurka",
    //    //labels: [OvertimeMealLabelEnum.Vegetarian]
    //  },
    //  {
    //    Id: 13,
    //    code: "S4",
    //    title: "Surdegsbröd bacon ägg",
    //    description: "Två skivor stekta surdegsbröd med nystekt bacon, stekt ägg, och rödlök"
    //  },
    //  {
    //    Id: 14,
    //    code: "S5",
    //    title: "Mörk baugette köttbullar",
    //    description: "Mörk baugette med köttbullar och rödbetssallad"
    //  }
    ];

    const leaveaAndAbsence = [];

    return {
      requestLeave,
      reportAbsence,
      //dockerUserInformation,
      overtimeMealOptions,
      //overtimeSnackOptions,
      leaveaAndAbsence
    };
  }

}

