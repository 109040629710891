import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  IconDefinition,
  faKey, faShip, faClipboardList, faMap, faWrench, faBinoculars, faCogs,
  faAnchor, faUnlockAlt, faMailBulk, faTools, faUserClock,
  faRoute, faBox, faBullhorn, faBalanceScaleLeft, faHouseUser
} from '@fortawesome/free-solid-svg-icons';

import { AuthenticationService } from './authentication.service';
import { ConfigurationService } from './configuration.service';
import { ConfigurationModel } from '../model/configuration.model';
import { TenantModel, SubAppModel } from '../model/tenant.model';
import { NewsItemModel } from '../model/newsitem.model';
import { UserInformationModel, isInRole } from '../model/user.model';

import { TextHelper } from '../helpers/text.helper';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private configurationModel: ConfigurationModel;
  private tenantModel: TenantModel;
  private currentUser: UserInformationModel;

  private _bsNewsItems = new BehaviorSubject<NewsItemModel[]>(new Array<NewsItemModel>());
  private _bsApplications = new BehaviorSubject<SubAppModel[]>(new Array<SubAppModel>());
  private _bsLoggedinLinks = new BehaviorSubject<SubAppModel[]>(new Array<SubAppModel>());
  private _bsAnonymousLinks = new BehaviorSubject<SubAppModel[]>(new Array<SubAppModel>());

  readonly newsItems = this._bsNewsItems.asObservable().pipe(distinctUntilChanged());
  readonly applicationLinks = this._bsApplications.asObservable().pipe(distinctUntilChanged());
  readonly loggedinLinks = this._bsLoggedinLinks.asObservable().pipe(distinctUntilChanged());
  readonly anonymousLinks = this._bsAnonymousLinks.asObservable().pipe(distinctUntilChanged());

  newsItemsValue: NewsItemModel[];
  applicationLinksValue: SubAppModel[];
  loggedinLinksValue: SubAppModel[];
  anonymousLinksValue: SubAppModel[];

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private translate: TranslateService,
    private translationHelper: TextHelper
  ) {
    this.authenticationService.currentUser
      .subscribe(x => {
        this.currentUser = x;

        if (this.tenantModel) {
          this.doFilterCheck();
        }
      });

    this.configurationService.allConfigsLoaded
      .subscribe(x => {

        if (x === true) {
          this.configurationModel = this.configurationService.getCurrentConfiguration();
          this.tenantModel = this.configurationService.getCurrentTenant();

          this.doFilterCheck();

          this.getHomeItems()
            .subscribe(data => {
              this.newsItemsValue = data;
              this._bsNewsItems.next(data);
            });
        }
      });
   
    this.translate.onLangChange
      .subscribe(langObj => {
        this.onLangUpdate();
      })
  }

  private doFilterCheck(): void {

    var apps = this._tapApplications(
      this.tenantModel.Applications.filter(app =>
        this._filterCheck(app)
      )
    );
    this.applicationLinksValue = apps;
    this._bsApplications.next(apps);
    var links = this._tapActionLinks(
      this.tenantModel.HomeLinksAnonymus.filter(link =>
        this._filterCheck(link)
      )
    );
    this.anonymousLinksValue = links;
    this._bsAnonymousLinks.next(links);

    var links2 = this._tapActionLinks(
      this.tenantModel.HomeLinksLoggedin.filter(link =>
        this._filterCheck(link)
      )
    );
    this.loggedinLinksValue = links2;
    this._bsLoggedinLinks.next(links2);
  }

  private onLangUpdate(): void {

    var homeApps = this.applicationLinksValue;
    if (homeApps)
    {
      homeApps.forEach(app => {
        app.DisplayText = this.translationHelper.resolveTranslation(app.Text);
      });

      this._bsApplications.next(homeApps);
    }

    var homeItems = this.newsItemsValue;
    if (homeItems)
    {
      homeItems.forEach(item => {
        item.DisplayHeading = this.translationHelper.resolveTranslation(item.Heading);
        item.DisplayInformation = this.translationHelper.resolveTranslation(item.Information);
      });

      this._bsNewsItems.next(homeItems);
    }

    var anonLinks = this.anonymousLinksValue;
    if (anonLinks)
    {
      anonLinks.forEach(link => {
        link.DisplayText = this.translationHelper.resolveTranslation(link.Text);
      });

      this._bsAnonymousLinks.next(anonLinks);
    }

    var loggedinLinks = this.loggedinLinksValue;
    if (loggedinLinks)
    {
      loggedinLinks.forEach(link => {
        link.DisplayText = this.translationHelper.resolveTranslation(link.Text);
      });

      this._bsLoggedinLinks.next(loggedinLinks);
    }
  }

  public getHomeItems(): Observable<NewsItemModel[]> {

    var newsItemsUrl = this.configurationService.getApiUrl('HeroCarousel');
    var apiHeaders = this.configurationService.getApiHeaders();

    return this.http.get<NewsItemModel[]>(newsItemsUrl + (this.tenantModel.IsNorrkopingEnv ? "/1" : ""), { headers: apiHeaders })
      .pipe(
        tap(data => {
          this._tapNewsItems(data);
        }),
        catchError(this.handleError)
      );
  }

  private _tapNewsItems(items: NewsItemModel[]): NewsItemModel[] {
    items.forEach(item => {
      item.DisplayHeading = this.translationHelper.resolveTranslation(item.Heading);
      item.DisplayInformation = this.translationHelper.resolveTranslation(item.Information);

      if (item.FileId) {
        item.FileUrl = this.configurationService.getApiBinaryUrl(item.FileId);
      }
    });
    return items;
  }

  private _filterCheck(model: SubAppModel): boolean {

    if (model.Licenses.length > 0 && this.tenantModel.Licenses.length > 0)
    {
      var tmp: boolean = model.Licenses.find(l => this.tenantModel.Licenses.indexOf(l) >= 0) !== undefined;
      if (!tmp) {
        return false;
      }
    }

    if (model.Roles.length > 0)
    {
      if (this.currentUser) {
        var tmp: boolean = model.Roles.find(r => isInRole(this.currentUser.UserRoles, r)) !== undefined;
        if (!tmp) {
          return false;
        }
      }
      else {
        return false;
      }
    }

    return true;
  }

  private _mapIcon(icon: string): IconDefinition {
    var out: IconDefinition;

    switch (icon)
    {
      case 'key':         { out = faKey }           break;
      case 'unlock':      { out = faUnlockAlt }     break;
      case 'ship':        { out = faShip }          break;
      case 'anchor':      { out = faAnchor }        break;
      case 'clipboard':   { out = faClipboardList } break;
      case 'map':         { out = faMap }           break;
      case 'wrench':      { out = faWrench }        break;
      case 'tools':       { out = faTools }         break;
      case 'binoculars':  { out = faBinoculars }    break;
      case 'cogs':        { out = faCogs }          break;
      case 'mailbulk':    { out = faMailBulk }      break;
      case 'user-clock':  { out = faUserClock }     break;
      case 'route':       { out = faRoute }         break;
      case 'containerWeb':{ out = faBox }           break;
      case 'preAdvice':    { out = faBullhorn }     break;
      case 'scale': { out = faBalanceScaleLeft }    break;
      case 'people-roof': { out = faHouseUser }     break;
    }

    return out;
  }

  private _tapApplications(items: SubAppModel[]): SubAppModel[] {
    items.forEach(app => {
        app.faIcon = this._mapIcon(app.Icon);
        app.faIconColor = app.Color;

        app.DisplayText = this.translationHelper.resolveTranslation(app.Text);

        if(app.NavRoute) {
          app.Route = this.resolveSubAppRoute(app);
        }
      });
    return items;
  }

  private _tapActionLinks(items: SubAppModel[]): SubAppModel[] {
    items.forEach(link => {
      link.faIcon = this._mapIcon(link.Icon);
      link.faIconColor = link.Color;

      link.DisplayText = this.translationHelper.resolveTranslation(link.Text);

      if (link.NavRoute) {
        link.Route = this.resolveSubAppRoute(link);
      }
    });
    return items;
  }

  private resolveSubAppRoute(subApp: SubAppModel): string {
    var outNav = "";

    switch (subApp.RouteMode) {
      case 'legacy': {
        outNav = this.tenantModel.AppBaseUrl + (subApp.NavRoute.substr(0, 1) == "/" ? "" : "/") + subApp.NavRoute;
      } break;
      case 'url':
      case 'blank':
      default: {
        outNav = subApp.NavRoute;
      } break;
    }

    if (this.configurationModel.ApplicationMode == "DEV") {
      switch (subApp.RouteMode) {
        case 'legacy': {
          subApp.RouteMode = "url";
        } break;
      }
    }

    return outNav;
  }

  private handleError(error: any) {
    // In a real world app, we might send the error to remote logging infrastructure
    // and reformat for user consumption
    console.error(error); // log to console instead
    return throwError(error);
  }
}
