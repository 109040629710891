import { Component, HostListener, Inject, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from 'ngx-localstorage';
import { DeviceDetectorService } from 'ngx-device-detector';

//import { SplashService } from './core/items/splash/service/splash.service';
import { SpinnerService } from './core/items/spinner/service/spinner.service';
import { SenchaLocalStorageService } from './core/shared/sencha/service/sencha-localstorage.service';

import { ConfigurationService } from './core/services/configuration.service';
import { AuthenticationService } from './core/services/authentication.service';
import { LoggerService } from './core/services/logger.service';
import { InAppService } from './core/services/inapp.service';
import { ThemeSkinService } from './core/services/themeskin.service';

import { InAppModel } from './core/model/inapp.model';
import { UserInformationModel } from './core/model/user.model';
import { InportAccessAuthDataModel, SecurityTokenModel } from './core/model/authentication.model';

import { LocalStorageNames } from './core/core.statics';
import { MainPath, GdprConfirmPath, NorrkopingMainPath } from './app-routes';
import { TextHelper } from './core/helpers/text.helper';
import { LanguageDbService } from './core/services/language-db-.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  styleTag: string = "";

  userSelectedLang: boolean = false;
  currentLang: string = 'en';
  
  currentUser: UserInformationModel;
  appInitMode: string = 'normal'; // normal or inapp

  private inAppModel: InAppModel;

  private afterInitNavigation: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private titleService: Title,
    //private splashService: SplashService,
    private senchaService: SenchaLocalStorageService,
    private spinnerService: SpinnerService,
    private configurationService: ConfigurationService,
    private authenticationService: AuthenticationService,
    private logger: LoggerService,
    private inAppService: InAppService,
    private themeSkinService: ThemeSkinService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
    private textHelper: TextHelper,
    private langDbService: LanguageDbService
  ) {
    /*this.param1 = this.route.snapshot.params.param1;
    this.param2 = this.route.snapshot.params.param2;

    this.param1 = this.route.snapshot.fragment;*/

    /*TODO: split below code into 2 setup strategies:
     1. if the Inport Access app fragments are present, setup the confingurations based on the dataParam values
     2. start the application as normal if the fragments are missing.
     */
    this.logger.debug("app.component::constructor");

    this.inAppModel = inAppService.parseWindowLocation(window.location);

    if (this.inAppModel && this.inAppModel.InAppLaunchedParam === true && this.inAppModel.DataParam !== undefined)
    {
      this.userSelectedLang = true;
      this.currentLang = this.inAppModel.LangParam;

      this.appInitMode = "inapp";
      this.doInAppInit();
    }
    else {
      this.appInitMode = "normal";
      this.doNormalInit();
    }
  }

  ngOnInit() {
    if (this.appInitMode == "inapp") {
      /**/
      this.configurationService.loadAllConfigurations();
    }
    else {
      /*normal*/
      this.configurationService.loadAllConfigurations();
    }
  }

  ngAfterViewInit() {
    /**/
  }

  scrolled: boolean = false;

  navUp: boolean = false;
  private lastScrollTop: number = 0;
  private readonly scrollDelta: number = 5;
  private readonly navUpThreshold: number = 58;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    var st = (
      document.body.scrollTop > 0 ?
        document.body.scrollTop :
        (
          document.documentElement.scrollTop > 0 ?
            document.documentElement.scrollTop :
            0
        )
    );

    if (Math.abs(this.lastScrollTop - st) <= this.scrollDelta)
      return;

    this.scrolled = (st > this.scrollDelta);

    if (st > this.lastScrollTop && st > this.navUpThreshold) {
      this.navUp = true;
    }
    else {
      this.navUp = false;
    }

    this.lastScrollTop = st;
  }

  private readonly shiftKeyPressDelta: number = 1000;
  private lastShiftKeyPress: number = 0;
  private shiftKeyPressCounter: number = 0;

  @HostListener('document:keydown.shift', ['$event'])
  onShiftKeydownHandler(event: KeyboardEvent) {
    
    var t: number = (new Date()).getTime();
    var d: number = t - this.lastShiftKeyPress;

    if (d > this.shiftKeyPressDelta)
      this.shiftKeyPressCounter = 0;
    else
      this.shiftKeyPressCounter++;

    if (this.shiftKeyPressCounter > 1) {

      //this.themeSkinService.toggleDarkMode();

      this.shiftKeyPressCounter = 0;
    }

    this.lastShiftKeyPress = t;
  }

  private readonly ctrlKeyPressDelta: number = 1000;
  private lastCtrlKeyPress: number = 0;
  private ctrlKeyPressCounter: number = 0;

  @HostListener('document:keydown.control', ['$event'])
  onCtrlKeydownHandler(event: KeyboardEvent) {

    //throw Error("Throwing a test error");

    var t: number = (new Date()).getTime();
    var d: number = t - this.lastCtrlKeyPress;

    if (d > this.ctrlKeyPressDelta)
      this.ctrlKeyPressCounter = 0;
    else
      this.ctrlKeyPressCounter++;

    if (this.ctrlKeyPressCounter > 1) {

      //this.themeSkinService.toggleAltSkin();

      this.ctrlKeyPressCounter = 0;
    }

    this.lastCtrlKeyPress = t;
  }

  useLanguage(language: string) {
    this.translate.use(language);
    moment.locale(this.currentLang);
  }

  private doNormalInit(): void
  {
    this.translate.setDefaultLang(this.currentLang);

    var local = this.localStorageService.get(LocalStorageNames.Locale);
    if (local != null) {
      this.userSelectedLang = true;
      this.currentLang = local;
    }
    else if (this.cookieService.check(LocalStorageNames.Locale)) {
      this.userSelectedLang = true;
      this.currentLang = this.cookieService.get(LocalStorageNames.Locale);
    }

    this.translate.onLangChange.subscribe(lang => {
      this._onLanguageChanged(lang);
    });

    this.configurationService.allConfigsLoaded
      .subscribe(z => {
        if (z === true)
        {
          this._onAllConfigLoaded();
        }
      });
  }

  private doInAppInit(): void {
    /**/
    this.translate.setDefaultLang(this.currentLang);

    this.translate.onLangChange.subscribe(lang => {
      this._onLanguageChanged(lang);
    });

    this.localStorageService.set(LocalStorageNames.DeviceToken, this.inAppModel.Data.DeviceApplicationToken);

    var st: SecurityTokenModel = new SecurityTokenModel
        st.SecurityToken = this.inAppModel.Data.SecurityToken;
        st.ValidUntil = new Date(this.inAppModel.Data.UtcValidUntil);

    this.localStorageService.set(LocalStorageNames.SecurityToken, st);

    this.authenticationService.setCurrentUserValue(
      this.inAppModel.Data.UserInformation,
      this.inAppModel.Data.UserType,
      this.inAppModel.Data.UserRoles
    );

    this.configurationService.allConfigsLoaded
      .subscribe(z => {
        if (z === true) {
          this._onAllConfigLoaded();
        }
      });
  }

  private _onLanguageChanged(lang: LangChangeEvent): void {
    this.currentLang = lang.lang;
    this.localStorageService.set(LocalStorageNames.Locale, this.currentLang);
    this.langDbService.updateLanguage(this.currentLang);

    //this.titleService.setTitle(this.translate.instant('app.title'));
  }

  configurationModel: any;
  tenantModel: any;

  isDevMode: boolean = false;
  configurationModelStr: string = '';
  tenantModelStr: string = '';

  private _onAllConfigLoaded(): void {

    this.configurationModel = this.configurationService.getCurrentConfiguration();
    this.configurationModelStr = JSON.stringify(this.configurationModel);

    this.isDevMode = (this.configurationModel.ApplicationMode.toUpperCase() == "DEV");

    if (!this.userSelectedLang && this.currentLang != this.configurationModel.Locale) {
      this.currentLang = this.configurationModel.Locale;
    }

    this.useLanguage(this.currentLang);

    this.tenantModel = this.configurationService.getCurrentTenant();
    this.tenantModelStr = JSON.stringify(this.tenantModel);

    if (this.tenantModel && this.tenantModel.StyleKey) {
      if (this.styleTag && this.styleTag.length > 0)
        this.renderer.removeClass(document.body, this.styleTag);

      this.styleTag = this.tenantModel.StyleKey;

      this.renderer.addClass(document.body, this.styleTag);
    }

    var osStyleTag = this.deviceDetectorService.os.toLowerCase();
    this.renderer.addClass(document.body, osStyleTag);

    
    if (this.deviceDetectorService.isMobile())
      this.renderer.addClass(document.body, "mobile");
    else if (this.deviceDetectorService.isTablet())
      this.renderer.addClass(document.body, "tablet");
    else
      this.renderer.addClass(document.body, "desktop");

    this.authenticationService.currentUser.subscribe(
      x => {
        this.currentUser = x;
      }
    );

    var appTitle = this.textHelper.resolveTranslation(this.tenantModel.HarbourName); 

    this.titleService.setTitle(appTitle);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

      this.useLanguage(event.lang);
    });

    if (this.appInitMode == "inapp")
    {
      /**/
      if (this.inAppModel.Data.UserInformation.GdprConfirmRequired && !this.inAppModel.Data.UserInformation.GdprConfirmed) {
        this.router.navigate([GdprConfirmPath]);
      }
      else {
        if (this.tenantModel.IsNorrkopingEnv) {
          this.router.navigate([NorrkopingMainPath]);
        }
        else {
          this.router.navigate([MainPath]);
        }
      }
    }
  }
}
