import { EmployeeModel } from './employee.model'

export class UserModel {
  id: number;
  username: string;
  password: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  email: string;
  company: string;
  active: boolean;
  locale: string;
}

export class UserInformationModel {
  UserId: number;
  UserType: number;
  UserRoles: Array<number>;
  UserName: string;
  FirstName: string;
  LastName: string;
  Email: string;
  MobilePhone: string;
  Remark: string;
  GdprConfirmRequired: boolean;
  GdprConfirmed: boolean;
  Employee?: EmployeeModel;
  DefaultSecuredArea: number;
  Initials: string;
  ImageUrl: string;
  CustomerId: number;
}

function roleCheck(userRoles: any, roleChallanger: number) {
  if (userRoles instanceof Array) {
    return userRoles.includes(roleChallanger);
  }
  else {
    return userRoles == roleChallanger;
  }

}

export function isInRole(userRoles: any, roleChallanger: any): boolean {
  if (!userRoles)
    return false;

  if (!roleChallanger)
    return true;

  if (roleChallanger instanceof Array) {
    for (var r in roleChallanger) {
      var rc = roleChallanger[r];
      // if we are checking a role that passed the 32 bit mark, we need to shift all the above bits into a lower window.
      // this is because the & operator doesn't support bit operations for integers larger then 32 bits
      if (roleCheck(userRoles, rc))
        return true;
    }
    return false;
  }
  else {
    return roleCheck(userRoles, roleChallanger);
  }
}

