export enum UserRoleEnum {
  None = 0,
  Disabled = 1,
  User = 2,
  SuperUser = 3, //3
  CustomerAdministrator = 4, //8,
  InPortExternalApps = 5, //16,
  GateGuard = 6, //32,
  ContentAdministrator = 7, //64,
  EamWorkOrder = 8, //128,
  MaintenanceRequestor = 9, //256,
  MaintenanceExternalTechnician = 10, //512,
  MaintenanceTechnician = 11, //1024,
  MaintenanceManager = 12, //2048,
  AgentPortalShipTerminalOperator = 13, //4096,
  AgentPortalShipAgent = 14, //8192,
  GateHarbourSecurityOfficer = 15, //16384,
  GateCustomerReceiver = 16, //32768,
  AgentPortalGoodsOwner = 17, //65536,
  AgentPortalPortControl = 18, //131072,
  GateCustomerReceiverAdmin = 19, //262144,
  HarbourVgmAdministrator = 20, //524288,
  VgmCustomer = 21, //1048576,
  VgmCustomerAdministrator = 22, //2097152,
  AgentPortalSteveDoringCo = 23, //4194304,
  WorkOrderDocker = 25, //16777216,
  WorkOrderExternal = 26, //33554432,
  WorkOrderForeman = 27, //67108864,
  WorkOrderHarbourAdministrator = 28, //134217728,
  BunkerOperatorAdmin = 29, //268435456,
  BunkerPortControl = 30, //536870912,
  GateUserAccessCardHolder = 31, //1073741824, //This is a temporary role, it's set for all users who have an access card
  BunkerOperator = 32, //2147483648,
  BunkerMobileUser = 33, //4294967296,
  DockerDocker = 34, //8589934592,
  DockerPlanner = 35, //17179869184,
  DockerCatering = 36, //34359738368,
  DockerExtra = 37, //68719476736,
  DockerManager = 38, //137438953472,
  AgentPortalSecurityGuard = 39, //274877906944
  
  SystemAdministrators = 40,
  CustomerAdministration = 41,
  Customer = 42,
  Public = 43,

  DockerTVUser = 44,
  
  ContainerWeb = 45, 
  PreAdvice = 46 
}
