export class SenchaSettingModel {
  DefaultSecuredArea: number = 0;
  DeviceToken: string = "";
  Id: number = 0;
  InAppLaunched: boolean = false;
  Key: string = "";
  Language: string = "";
  LocalStoragePath: string = "";
  PushToken: string = "";
  SecurityToken: string = "";
  SecurityTokenExpires: number = 0;
  UserInformation: SenchaUserInformationModel = new SenchaUserInformationModel();
  UserRoles: Array<number>;
  UserType: number = 0;
  Version: number = 0;
}

export class SenchaUserInformationModel {
  Email: string = "";
  Employee: SenchaEmployeeModel = new SenchaEmployeeModel;
  FirstName: string = "";
  GdprConfirmRequired: boolean = false;
  LastName: string = "";
  MobilePhone: string = "";
  Remark: string = "";
  UserId: number = 0;
  UserName: string = "";
}

export class SenchaEmployeeModel {
  Active: boolean = false;
  Code: string = "";
  Description: string = ""
  EmployeeGroups: Array<SenchaEmployeeGroupModel> = new Array<SenchaEmployeeGroupModel>();
  EmployeeTrades: Array<SenchaEmployeeTradeModel> = new Array<SenchaEmployeeTradeModel>();
  Id: number = 0;
  Name: string = "";
}

export class SenchaEmployeeGroupModel {
  Active: boolean = false;
  Code: string = "";
  Description: string = "";
  Id: number = 0;
  Name: string = "";
}

export class SenchaEmployeeTradeModel {
  Active: boolean = false;
  Code: string = "";
  Description: string = "";
  Id: number = 0;;
  Name: string = "";
}

export class SenchaHeroCarouselItemModel {
  Category: string = "";
  Changed: Date = new Date(2000, 0, 1);
  FileId: string = "";
  Heading: string = "";
  Id: string = "";
  Image: string = "";
  Information: string = "";
  LinkDestination: string = "";
  LinkType: string = "";
  SortOrder: number = 0;
}
