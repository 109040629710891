import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, retryWhen, distinctUntilChanged } from 'rxjs/operators';

import { ConfigurationService } from './configuration.service';
import { LogTypeEnum } from '../../_enums/logtype.enum';
import { ConfigurationModel } from '../model/configuration.model';
import { LoggerMessageModel } from '../model/loggermessage.model';

import { RetryStrategy } from 'src/app/_handlers/retry-strategy';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private devMode: boolean = false;
  private loggerMode: number = 0; // 0 = console, 1 = remote, 2 = both
  private configurationModel: ConfigurationModel;

  private _allMessageCounter: number = 0;
  private _bsAllMessages = new BehaviorSubject<LoggerMessageModel[]>([]);
  readonly allMessages = this._bsAllMessages.asObservable().pipe(distinctUntilChanged());

  private _delayedRemoteMessages: Array<LoggerMessageModel> = [];

  private _forcedDisabled: boolean = false;

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService
  ) {

    this.configurationService.configuration
      .subscribe(x => {

        this.configurationModel = x;
        this.devMode = (this.configurationModel.ApplicationMode == "DEV");

        switch (this.configurationModel.LoggerMode.toUpperCase()) {
          case "R": { this.loggerMode = 1; } break;
          case "B": { this.loggerMode = 2; } break;
          default: { this.loggerMode = 0; } break;
        }
      });

    this.configurationService.allConfigsLoaded
      .subscribe(x => {

        if (x != true)
          return;
        
        if (this.loggerMode >= 1 && this._delayedRemoteMessages.length > 0)
        {
          this._delayedRemoteMessages.forEach(
            (m) => {
              this._doSendRemoteLog(m.Message, m.Type);
            }
          );
        }

        this._delayedRemoteMessages = [];
      });
  }

  private _addToAll(message: string, logType: LogTypeEnum): void {
    if (this.configurationModel.ApplicationMode == "DEV") {
      var b = this._bsAllMessages.value;

      b = (b.length > 49 ? b.splice(b.length - 1, 1) : b);

      var m = new LoggerMessageModel();
      m.Id = this._allMessageCounter++;
      m.Message = message;
      m.Type = logType;

      b.unshift(m);

      this._bsAllMessages.next(b);
    }
  }

  private _log(message: string, logType: LogTypeEnum): Promise<void> {

    this._addToAll(message, logType);

    if (this.loggerMode == 0 || this.loggerMode == 2)
    {
      switch (logType) {
        case LogTypeEnum.info: { console.info(message); } break;
        case LogTypeEnum.warning: { console.warn(message); } break;
        case LogTypeEnum.error: { console.error(message); } break;
        case LogTypeEnum.debug: { console.debug(message); } break;
      }
    }

    var ret: Promise<void>;

    if (this.loggerMode == 1 || this.loggerMode == 2) {
      ret = this._remoteLog(message, logType);
    }
    else {
      ret = new Observable<void>().toPromise();
    }

    return ret;
  }

  public log(message: string): Promise<void> {
    return this._log(message, LogTypeEnum.info);
  }

  public info(message: string): Promise<void> {
    return this._log(message, LogTypeEnum.info);
  }

  public warn(message: string): Promise<void> {
    return this._log(message, LogTypeEnum.warning);
  }

  public error(message: string): Promise<void> {
    return this._log(message, LogTypeEnum.error);
  }

  public debug(message: string): Promise<void> {
    return this._log(message, LogTypeEnum.debug);
  }

  private _remoteLog(message: string, type: LogTypeEnum): Promise<void> {

    if (!this.configurationModel.ConfigurationKey && !this._forcedDisabled) {

      var m = new LoggerMessageModel();
        m.Type = type;
        m.Message = message;

      this._delayedRemoteMessages.push(m);

      return new Observable<void>().toPromise();
    }

    return this._doSendRemoteLog(message, type);
  }

  private _doSendRemoteLog(message: string, type: LogTypeEnum): Promise<void>
  {
    var logUrl = this.configurationService.getApiUrl('Log');
    var apiHeaders = this.configurationService.getApiHeaders();

    var body = JSON.stringify({
      Type: type,
      Message: message
    });

    return this.http.post<void>(logUrl, body, { headers: apiHeaders })
      .pipe(
        retryWhen(RetryStrategy()),
        catchError(this.handleError)        
      )
      .toPromise();
  }

  private handleError = (error: HttpErrorResponse): Observable<void> => {
    // In a real world app, we might send the error to remote logging infrastructure
    // and reformat for user consumption
    console.error(error); // log to console instead

    if (error.status == 401)
      this._forcedDisabled = true;

    // we do not want to throw an exception here, because it will create an infinit loop of exceptions.
    // instead add the message to the all message so it will displayed in the dev-panel.
    this._addToAll(JSON.stringify(error, null, 2), LogTypeEnum.error)

    return of();
  }
}
