
import { Routes } from '@angular/router';

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule)
  },
  {
    path: 'docker',
    loadChildren: () => import('./docker/docker.module').then(m => m.DockerModule)
  },
  {
    path: 'workorder',
    loadChildren: () => import('./workorder/workorder.module').then(m => m.WorkorderModule)
  },
  {
    path: 'Gate',
    loadChildren: () => import('./gate/gate.module').then(m => m.GateModule)
  },
  {
    path: 'pipeplanner',
    loadChildren: () => import('./pipebooking/pipebooking.module').then(p=> p.PipeBookingModule)
  },
  {
    path: '**',
    redirectTo: 'error'
  }
]
