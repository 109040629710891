import { Injectable } from '@angular/core';
import { ScreenSize } from './screen-size.enum';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ResizeService {

  get onResize$(): Observable<ScreenSize> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<ScreenSize>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  private currentSize: ScreenSize;

  public getCurrentSize() {
    return this.currentSize;
  }

  onResize(size: ScreenSize) {
    this.currentSize = size;

    this.resizeSubject.next(size);
  }
}
