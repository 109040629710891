import { Output, EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SidebarService {
  @Output() afterModeChange$: EventEmitter<boolean> = new EventEmitter();
  constructor() {

  }
  afterModeChange(isCompactMode: boolean) {
    this.afterModeChange$.emit(isCompactMode);
  }
}
