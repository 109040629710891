import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { timeout, catchError, distinctUntilChanged } from 'rxjs/operators';

import { InAppModel } from '../model/inapp.model';

import { LoggerService } from './logger.service';
import { InportAccessAuthDataModel } from '../model/authentication.model';

@Injectable({
  providedIn: 'root',
})
export class InAppService {

  private _bsInApp = new BehaviorSubject<InAppModel>(new InAppModel);

  readonly inApp = this._bsInApp.asObservable().pipe(distinctUntilChanged());

  constructor(
    private logger: LoggerService
  ) {
  }

  public parseWindowLocation(windowLocation: any): InAppModel
  {
    var inAppLaunchedParam: boolean = false;
    var routeHash: string = windowLocation.hash;
    var routeSearch: string = windowLocation.search;
    var langParam: string = "";
    var dataParam: string = "";
    var dataModel: InportAccessAuthDataModel;

    var frag = '';
    var uriParams = '';
    if (routeHash) {
      this.logger.debug("window.location.hash: " + routeHash);

      frag = routeHash;
      if (frag[0] == '#') {
        frag = frag.substr(1);
      }

      var s = frag.indexOf('?');
      if (s) {
        uriParams = frag.substr(s + 1);
      }
    }
    else if (routeSearch) {
      this.logger.debug("window.location.search: " + routeSearch);

      uriParams = routeSearch;
      if (uriParams[0] == '?') {
        uriParams = uriParams.substr(1);
      }
    }

    if (uriParams) {
      var params = uriParams.split('&');
      params.forEach(param => {

        var parts = [];
        var s = param.indexOf('=');
        if (s) {
          parts.push(param.substr(0, s));
          parts.push(param.substr(s + 1));
        }

        if (parts.length == 2) {
          switch (parts[0]) {
            case 'app': {
              inAppLaunchedParam = parts[1] === "true";
            } break;
            case 'lang': {
              langParam = parts[1];
            } break;
            case 'data': {
              var d = atob(decodeURIComponent(parts[1]));
              this.logger.debug("inapp data-parameter: " + d);
              dataModel = JSON.parse(d);

              dataParam = JSON.stringify(dataModel, undefined, 2);
            } break;
          }
        }
      });
    }

    var model = new InAppModel;
    model.RouteHash = routeHash;
    model.RouteSearch = routeSearch;
    model.InAppLaunchedParam = inAppLaunchedParam;
    model.LangParam = langParam;
    model.DataParam = dataParam;
    model.Data = dataModel;

    this._bsInApp.next(model);

    return model;
  }
}
