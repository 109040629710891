import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange
      .subscribe(() => {
        this.getAndInitTranslations();
      });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translate
      .get(['Pagination.ItemsPerPage'])
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation['Pagination.ItemsPerPage'];
        this.changes.next();
      });
  }
}
