import { InportAccessAuthDataModel, SecurityTokenModel } from './authentication.model';

export class InAppModel {
  RouteHash: string = "";
  RouteSearch: string = "";
  InAppLaunchedParam: boolean = false;
  LangParam: string = "";
  DataParam: string = "";
  Data: InportAccessAuthDataModel = null;
}
