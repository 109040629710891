import { TokenModel } from './token.model';
import { UserInformationModel } from './user.model';

export class AuthenticationBasicRequestModel {
  username: string = "";
  password: string = "";
  pincode: string = "";
}

export class AuthenticationBasicResponseModel {
  Success: boolean;
  UserType: number;
  UserRoles: any;
  Reason: string;
  SecurityToken: TokenModel;
  UtcValidUntil: Date = new Date(2000, 0, 1);
  UserInformation: UserInformationModel;
  HideNonLeaveMenus: boolean;
  EnableGateArrivalsMenu: boolean;
  EnableWorkOverViewMenu: boolean;
  EnableWorkReport: boolean;
}

export class AuthenticationPrepareResponseModel {
  Success: boolean;
  Data: string;
  Reason: string;
  UtcValidUntil: Date = new Date(2000, 0, 1);
}

export class SecurityTokenModel {
  SecurityToken: string = "";
  ValidUntil: Date = new Date(2000, 0, 1);
}

export class ChangePasswordModel {
  oldPassword: string = "";
  newPassword: string = "";
  newPassword2: string = "";
}

export class ChangePasswordResponseModel {
  Success: boolean;
  Reason: string;
}

export class RecoverPasswordResponseModel {
  Success: boolean;
  Reason: string;
}

export class GdprConfirmResponseModel {
  Success: boolean;
  Reason: string;
}

export class InportAccessAuthDataModel {
  Success: boolean;
  WebbAppUrl: string = "";
  ReIssueUntil: Date = new Date(2000, 0, 1);
  UtcValidUntil: Date = new Date(2000, 0, 1);
  DeviceApplicationToken: string;
  SecurityToken: string;
  UserType: number;
  UserRoles: Array<number>;
  UserInformation: UserInformationModel;
}
